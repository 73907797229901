<template>
  <div class="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="font-weight-bolder">Hochladen</h5>
          <label for="textFormInput" class="form-label">Beschreibe um was es sich in deinem Beitrag
          handelt </label>
          <div class="row pb-3">
            <div class="col p-3 pb-4">
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="mb-3">
                      <textarea class="form-control" id="textFormInput" rows="5" maxlength="1000"
                                v-model="postText"></textarea>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="mb-3">
                    <label for="hashtagFormInput" class="form-label">Hashtags</label>
                    <textarea class="form-control" id="hashtagFormInput" rows="3" maxlength="1000"
                              v-model="postHashtags"></textarea>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="mb-3">
                    <label for="hashtagFormInput" class="form-label">Zusatzinformation</label>
                    <textarea class="form-control" id="hashtagFormInput" rows="3" maxlength="1000"
                              v-model="additionalInfoText"></textarea>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <label class="form-label">Kategorie</label>
                  <select
                      id="category-Select"
                      class="form-control m-auto"
                      name="categorySelect"
                      v-model="selectedCategory">
                    >
                      <option v-for="option in category" :value="option.category">
                        {{ option.category }}
                      </option>
                  </select>
                </div>
                <div class="col-6 mb-3">
                  <label>Zeitpunkt</label>
                  <soft-input
                      id="zeitpunkt"
                      v-model="time"
                      dataformatas="HH:mm"
                      filled
                      type="time"
                      hint="Zeitpunkt der Veröffentlichung"
                  />
                </div>
                <div class="col-6 mb-3">
                  <label>Datum</label>
                  <soft-input
                      id="datum"
                      v-model="date"
                      filled
                      type="date"
                      hint="Datum der Veröffentlichung"
                  />
                </div>
                <div class="d-flex align-items-center flex-column">
                  <div class="form-label mb-2">Social-Media-Plattform (min. 1)</div>
                  <div class="gap-3 d-flex flex-wrap" id="social-platform">
                    <button type="button" style="border:1px" :style="instagramStyle" class="badge badge-lg"
                            @click="this.instagram = !this.instagram">
                      <font-awesome-icon :icon="['fab', 'instagram']" size="lg"/>

                    </button>
                    <button type="button" style="border:1px" :style="facebookStyle" class="badge badge-lg"
                            @click="this.facebook = !this.facebook">
                      <font-awesome-icon :icon="['fab', 'facebook']" size="lg"/>
                    </button>
                    <button type="button" style="border:1px" :style="linkedinStyle" class="badge badge-lg"
                            @click="this.linkedin = !this.linkedin">
                      <font-awesome-icon :icon="['fab', 'linkedin']" size="lg"/>
                    </button>
                  </div>
                  <div v-if="socialMediaErrorMessage" class="text-danger d-flex align-items-center mt-2">
                    Bitte wählen Sie mindestens
                    Social-Media-Platform
                  </div>
                </div>
                <div class="mb-3" v-if="this.instagram">
                  <div class="d-flex justify-content-between">
                    <label for="instaText" class="form-label">Instagram</label>
                    <button @click="copy('instaText')" class="border-0" style="background-color:white" ><i
                        class="fa fa-clipboard"></i></button>
                  </div>
                  <textarea v-model="this.instagramText" class="form-control" id="instaText" rows="5" 
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-if="this.facebook">
                  <div class="d-flex justify-content-between">
                    <label for="facebookText" class="form-label">Facebook</label>
                    <button @click="copy('facebookText')" class="border-0" style="background-color:white" ><i
                        class="fa fa-clipboard"></i></button>
                  </div>
                  <textarea v-model="this.facebookText" class="form-control" id="facebookText" rows="5" 
                            maxlength="3000"></textarea>
                </div>
                <div class="mb-3" v-if="this.linkedin">
                  <div class="d-flex justify-content-between">
                    <label for="linkedinText" class="form-label">LinkedIn</label>
                    <button @click="copy('linkedinText')" class="border-0" style="background-color:white" ><i
                        class="fa fa-clipboard"></i></button>
                  </div>
                  <textarea v-model="this.linkedinText" class="form-control" id="linkedinText" rows="5" 
                            maxlength="3000"></textarea>
                </div>
              </div>
            </div>
            <div class="d-flex button-row">
              <button
                  class="mb-0 btn bg-dark js-btn-next text-white"
                  type="button"
                  title="Zurücksetzen"
                  data-bs-dismiss="modal"
                  @click="closeModal"
                  ref="close"
              >
                Abbrechen
              </button>
              <button
                  class="mb-0 btn bg-primary ms-auto js-btn-next"
                  type="button"
                  title="Senden"
                  @click="submit"
              >
                Senden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid ">
    <div class="mt-3 m-4">
      <div class="mb-4 card p-3">
        <FullCalendar :key="calendarKey" :options='calendarOptions' selectabl/>
      </div>
    </div>
  </div>
</template>


<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import api, {apiGet} from "@/api/api";
import {nextTick} from "vue";
import setNavPills from "@/assets/js/nav-pills";
import setTooltip from "@/assets/js/tooltip";

import { Modal } from "bootstrap";
import SoftInput from "@/components/SoftInput.vue";
import interactionPlugin from '@fullcalendar/interaction';

import FontAwesomeIcon from "@fortawesome/vue-fontawesome";
import moment from "moment";

export default {
  components: {
    FullCalendar,
    FontAwesomeIcon,
    SoftInput,
  },

  data: function () {
    return {
      uniqueModal: null,
      time: null,
      date: null,
      category: null,
      selectedCategory: null,
      postText: "",
      postHashtags: "",
      additionalInfoText: "",

      instagram: false,
      facebook: false,
      linkedin: false,
      instagramText: "",
      facebookText: "",
      linkedinText: "",

      socialMediaErrorMessage: false,

      post: null,
      editing: false,

      loadingData: false,
      calendarKey: 0,
      postList: [],
      calendarOptions: {
        editable: true,
        eventDrop: (info) => {
          let id = info.event._def.publicId
          let timestamp = decodeURI(moment.utc(info.event._instance.range.start).format('HH:mm'))
          let date = decodeURI(moment.utc(info.event._instance.range.start).format('yyyy-MM-DD'))
          this.updateWithDrag(id, date, timestamp)
        },
        eventClick: (info) => {
          this.resetForm()
          let id = info.event._def.publicId
          this.editing = true
          this.editPost(id)
        },
        locale: 'de',
        plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
          left: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: 'title',
        },
        weekends: true,
        firstDay: 1,
        buttonText: {
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
        },
        allDayText: 'Jeden Tag',
        views: {
          month: {
            titleFormat: {year: 'numeric', month: 'long'}
          },
          week: {
            titleFormat: {year: 'numeric', month: 'long', day: 'numeric'}
          }
        },
        events: [],
        eventDisplay: 'block',
        selectable: true,

        dateClick: (info) => {
          this.resetForm()
          if(info.dateStr.indexOf("T") > -1){
            this.date = info.dateStr.split("T")[0]
            this.time = info.dateStr.split("T")[1]
            this.time = this.time.split("+")[0]
          }else{
            this.date = info.dateStr
          }
          this.editing = false
          this.uniqueModal = new Modal(document.getElementById("exampleModal"),{ keyboard: false });
          this.uniqueModal.show();
        },
      }
    }
  },

  computed: {
    activeId() {
      return parseInt(this.$store.state.activeId);
    },
    instagramStyle() {
      return this.instagram ? 'color: white; background-color: #e91e63' : 'color: grey'
    },
    facebookStyle() {
      return this.facebook ? 'color: white; background-color: #1a237e' : 'color: grey'
    },
    linkedinStyle() {
      return this.linkedin ? 'color: white; background-color: #2196f3' : 'color: grey'
    },
  },

  methods: {
    resetForm(){
      this.time = null
      this.date = null
      this.additionalInfoText = ""
      this.postText = ""
      this.postHashtags = ""
      this.selectedCategory = null
      this.instagram = false
      this.facebook = false
      this.linkedin = false
      this.socialMediaErrorMessage = false
    },
    async loadCategories(){
      const resp = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
      this.category = resp.data
    },

    async submit(){
      if(this.editing){
        await this.update()
      }else{
        await this.upload()
      }
    },

    async updateWithDrag(id, datestamp, timestamp){
      let resp = await apiGet(localStorage.getItem('adminId'),'/post/edit?id=' + id)
      const post = resp.data

      // das mit der category ist ne potenzielle fehlerquelle
      // wenn man das objekt kriegt ist es ein objekt mit id und namen und wenn man es verschicken will
      // dann beschwert sich java spring boot wegen nested json object
      // deswegen diese temporäre lösung
      const uploadData = {
        ...post,
        category: post.category.category,
        publicationDate: new Date(datestamp + ' ' + timestamp),
      }

      localStorage.setItem('companyId', this.$store.state.activeId)
      await api.put('/post/save?companyId=' + this.$store.state.activeId.toString(), uploadData)
    },

    async update(){
      this.socialMediaErrorMessage = false

      let tempinsta = ''
      let tempface = ''
      let templinked = ''

      if (this.facebook && this.facebookText !== 'true')
      {
        tempface = this.facebookText
      }
      if (this.instagram && this.instagramText !== 'true')
      {
        tempinsta = this.instagramText
      }
      if (this.linkedin && this.linkedinText !== 'true')
      {
        templinked = this.linkedinText
      }

      const uploadData = {
        ...this.post,

        text: encodeURI(this.postText),
        hashtags: encodeURI(this.postHashtags),
        additionalInfo: encodeURI(this.additionalInfoText),
        category: this.selectedCategory,

        publicationDate: new Date(this.date + ' ' + this.time),

        linkedinText: encodeURI(templinked),
        facebookText: encodeURI(tempface),
        instagramText: encodeURI(tempinsta),
      }

      if (this.linkedin || this.facebook || this.instagram) {
        if (this.category !== '') {
          localStorage.setItem('companyId', this.$store.state.activeId)
          await api.put('/post/save?companyId=' + this.$store.state.activeId.toString(), uploadData)
          await this.setCompany()
          this.closeModal()
        }
      } else {
        this.socialMediaErrorMessage = true;
      }
    },
    async upload() {
      this.socialMediaErrorMessage = false
      const uploadData = {
        images: [],
        text: this.postText,
        hashtags: this.postHashtags,
        additionalInfo: this.additionalInfoText,
        category: this.selectedCategory,

        publicationDate: new Date(this.date + ' ' + this.time),

        linkedinText: this.linkedin ? 'true' : '',
        facebookText: this.facebook ? 'true' : '',
        instagramText: this.instagram ? 'true' : '',
      }

      if (this.linkedin || this.facebook || this.instagram) {
        if (this.category !== '') {
          localStorage.setItem('companyId', this.$store.state.activeId)
          await api.post('/post/save?companyId=' + this.$store.state.activeId.toString(), uploadData)
          await this.setCompany()
          this.closeModal()
        }
      } else {
        this.socialMediaErrorMessage = true;
      }
    },

    async editPost(id) {
      let resp = await apiGet(localStorage.getItem('adminId'),'/post/edit?id=' + id)
      const post = resp.data

      this.post = post

      this.postText = decodeURI(post.text)
      this.additionalInfoText = decodeURI(post.additionalInfo)
      this.postHashtags = decodeURI(post.hashtags)
      this.selectedCategory = post.category != null ? post.category.category : null
      this.time = decodeURI(moment(resp.data.publicationDate).format('HH:mm'))
      this.date = decodeURI(moment(resp.data.publicationDate).format('yyyy-MM-DD'))
      this.instagram = post.instagramText !== ""
      this.facebook = post.facebookText !== ""
      this.linkedin = post.linkedinText !== ""
      this.instagramText = post.instagramText !== "true" ? decodeURI(post.instagramText ): ''
      this.facebookText = post.facebookText !== "true" ? decodeURI(post.facebookText) : ''
      this.linkedinText = post.linkedinText !== "true" ? decodeURI(post.linkedinText) : ''

      this.uniqueModal = new Modal(document.getElementById("exampleModal"),{ keyboard: false });
      this.uniqueModal.show();
    },

    async setCompany() {
      let resp;
      this.loadingData = true;
      if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
        resp = await apiGet(localStorage.getItem('adminId'),'/post?companyId=' + localStorage.getItem('companyId'))
      } else {
        resp = await apiGet(localStorage.getItem('adminId'),'/post?companyId=' + this.activeId)
      }

      this.postList = resp.data.posts.map((x) => {
        return {
          text: decodeURI(x.text),
          publicationDate: decodeURI(x.publicationDate) || new Date(),
          ...x,
        }
      })
      for (let item of this.postList)
      {
        item.instagramText = decodeURI(item.instagramText)
        item.facebookText = decodeURI(item.facebookText)
        item.linkedinText = decodeURI(item.linkedinText)
        item.additionalInfo = decodeURI(item.additionalInfo)
        item.hashtags = decodeURI(item.hashtags)
        item.text = decodeURI(item.text)
      }

      this.postList = this.postList.reverse()
      this.loadingData = false;

      await this.setEvents();
    },

    async setEvents() {
      this.calendarOptions.events = [];

      for (let i = 0; i <= this.postList.length; i++) {
        const item = this.postList[i];
        if (this.postList[i]?.publicationDate) {
          this.calendarOptions.events[i] = {
            id: item.id,
            title: this.postList[i].text,
            date: this.postList[i].publicationDate,
          }
        }
      }
    },

    closeModal(){
      this.$refs.close.click()
    },
    copy(id) {
      var copyText = document.getElementById(id);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
    },
  },

  mounted() {
    this.$store.state.showNavbarMini = false;
    this.$store.state.isAbsolute = true;
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    this.setCompany()
    this.loadCategories()
  },
};

</script>

<style>
#exampleModal{
  top:0px;
}

.modal-dialog{
  max-width:50vw !important;
}

</style>