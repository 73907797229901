<template>
  <div class="col-12 col-md-4"
       @click="emitToParent">
    <div v-if="loading" class="card" style="max-height: 150px;">
      <Skeleton class="skeleton-custom" height="100%"></Skeleton>
    </div>
    <div v-else class="card border h-100">
      <div>
        <soft-checkbox
            @click.passive
            v-if="this.$parent.multiSelection"
            class="position-absolute top-0 p-2"
            style="z-index: 9999; right: 0"
            :checked="selected"
            alignment="justify-content-end">
        </soft-checkbox>
        <router-link
            :to="!multiSelections ? linkPath : ''"
            class="card card-blog card-plain h-100 position-relative"
        >
          <div class="card-body d-flex p-3">
            <div class="d-flex flex-wrap flex-md-nowrap w-100 h-100 ">
              <div class="col-lg-6 col-12 col-sm-8 order-last order-lg-first h-100 h-100 pe-lg-1 ps-3 ">
                <div class="d-flex flex-column justify-content-start h-100" style="min-height: 190px">
                  <p :class="`mb-1 pt-2 text-sm text-${labelColor}`">{{ label }}</p>
                  <div :class="`mb-2 text-xs`">{{ statusLabel }}</div>
                  <h5 class="font-weight-bold text-ellipsis text-sm" >{{ title }}</h5>
                  <p class="mb-2 text-ellipsis text-xs "> {{ description }} </p>
                  <p class="text-xs"> {{ publicationDate }}</p>
                  <div class="row text-sm">
                    <font-awesome-icon :icon="['fab', 'instagram']" size="lg" v-if="instagram" class=" col-1"/>
                    <font-awesome-icon :icon="['fab', 'facebook']" size="lg" v-if="facebook" class=" col-1"/>
                    <font-awesome-icon :icon="['fab', 'linkedin']" size="lg" v-if="linkedin" class=" col-1"/>

                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-12 col-sm-3 d-flex align-items-center text-center order-first order-lg-last justify-content-center h-lg-100">
                <img class="border-radius-lg z-index-2"
                     style="max-height: 10rem; object-fit: cover; max-width: 100%"
                     :src="image" alt="postImg">
              </div>
            </div>
          </div>
        </router-link>

        <slot></slot>
      </div>

    </div>
  </div>
</template>

<script>
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import Skeleton from "primevue/skeleton";
import {hasClass} from "leaflet/src/dom/DomUtil";
import api, {apiGet} from "@/api/api";

export default {
  name: "PostCard",
  components: {
    SoftCheckbox,
    Skeleton,
  },
  data() {
    return {
      selected: false,
      image: ''
    }
  },
  props: {
    projectId: {
      type: Number,
      default: 0,
    },
    linkPath: {
      type: String,
      default: "",
    },
    labelColor: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
    },
    label: {
      type: String,
      default: "",
    },
    statusLabel: {
      type: String,
      default: "",
    },
    multiSelections: {
      type: Boolean,
      default: false
    },
    instagram: {
      type: String,
      default: null,
    },
    facebook: {
      type: String,
      default: null,
    },
    linkedin: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "Default Project Card",
    },
    description: {
      type: String,
      default: "",
    },
    publicationDate: {
      type: String,
    },
    action: {
      type: Object,
      route: String,
      color: String,
      label: String,
      default: () => {
      },
    },
    authors: {
      type: Array,
      image: String,
      name: String,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    skeletonPosts: {
      type: Number,
      default: 4,
    }
  },

  async mounted() {
    let resp;
    resp = await apiGet(localStorage.getItem('adminId'),'/post/images?postId=' + this.id)
    if(resp.data[0].base64Image.length > 0){
      if (resp.data[0].base64Image.includes('video')) {
        this.getFirstFrameAsBase64(resp.data[0].base64Image).then(base64String => {
          this.image = base64String
        })
      } else {
        this.image = resp.data[0].base64Image
      }
    }else{
       this.image = '';
    }
  },

  methods: {
    hasClass,
    emitToParent(item) {
      if (this.multiSelections) {
        this.selected = !this.selected
        this.$parent.addToBeDeletedList(this.projectId)
      }
    },
    getFirstFrameAsBase64(base64Video) {
      return new Promise((resolve, reject) => {
        // Create video and canvas elements
        const videoElement = document.createElement('video');
        videoElement.muted = true
        const canvasElement = document.createElement('canvas');
        const context = canvasElement.getContext('2d');

        // Set video source to the base64 video string
        videoElement.src = base64Video;
        videoElement.crossOrigin = 'anonymous'; // Avoid CORS issues if needed

        // Wait for video metadata to load
        videoElement.addEventListener('loadedmetadata', () => {
          // Set canvas size to match the video's dimensions
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;

          // Capture the first frame when the video is ready
          videoElement.currentTime = 0;
        });

        // Capture the first frame as soon as it is loaded
        videoElement.addEventListener('seeked', () => {
          // Draw the first frame onto the canvas
          context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

          // Convert the canvas content to a base64 image string
          const base64Image = canvasElement.toDataURL('image/png');
          resolve(base64Image);
        });

        // Handle video loading errors
        videoElement.onerror = () => {
          reject('Failed to load video.');
        };
      });
    }
  },
  watch: {
    multiSelections(val) {
      if (!val) {
        this.selected = false
      }
    }
  }
};
</script>
<style>
.skeleton-custom {
  background-color: #f0f0f0;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
}

.skeleton-custom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100% !important;
  }
}
</style>