<template>
  <div class="py-4 h-100 container-fluid">
    <div class="row mt-3">
      <div class="text-center col-12">
        <h3>Easy Social - Hochladen</h3>
        <div class="modal fade pe-4" id="exampleModal" tabindex="-1"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true"
             v-if="noCategories">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header d-flex row" style="margin-left: 5px; margin-right:5px">
                <h1 class="modal-title fs-5 text-wrap" id="exampleModalLabel">Neue Kategorie erstellen</h1>
              </div>
              <div class="modal-body">
                <label>Neue Kategorie</label>
                <soft-input placeholder="" v-model="firstCategory" id="name"></soft-input>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn" id="closebtn"
                        style="color:white; background-color: #333"
                        data-bs-dismiss="modal" @click="clear()">Abbrechen
                </button>
                <button type="button" class="btn btn-primary" @click="newCategory">Erstellen</button>
              </div>
            </div>
          </div>
        </div>
        <h5 class="font-weight-normal">
          Erstelle ganz einfach Posts mit Bildern, Text und Hashtags!
        </h5>
        <div class="mb-4 multisteps-form">
          <!--progress bar-->
          <div class="row">
            <div class="mx-auto my-5 col-12 col-lg-10">
              <div class="multisteps-form__progress">
                <div
                    class="multisteps-form__progress-btn"
                    :class="activeStep >= 0 ? activeClass : ''"
                >
                  <span class="text-dark">Bilder hinzufügen</span>
                </div>
                <div
                    class="multisteps-form__progress-btn"
                    :class="activeStep >= 1 ? activeClass : ''"
                >
                  <span class="text-dark">Format anpassen</span>
                </div>
                <div
                    class="multisteps-form__progress-btn"
                    :class="activeStep === 2 ? activeClass : ''"
                >
                  <span class="text-dark">Hochladen</span>
                </div>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-10">
              <form class="multisteps-form__form" @submit.prevent>
                <!--single form panel-->
                <div v-if="activeStep === 0" class="p-3 bg-white card">
                  <div class="text-center row">
                    <div class="mx-auto mb-4 mt-2 col-10">
                      <h5 class="font-weight-normal"> Lade hier deine Bilder hoch! </h5>
                    </div>
                    <div class="col-12px-3 text-dark">
                      <form
                          @submit.prevent="imageFileUpload"
                          id="dropzone"
                          class="form-control dropzone"
                      >
                        <div class="fallback">
                          <input class="text-dark" name="file" type="file" multiple @change="handleFileDeleted"/>
                        </div>
                      </form>
                      <div v-if="1 > 0">
                        <div class="text-danger small py-3">{{ message }}</div>
                      </div>
                      <div class="mt-2 button-row d-flex">
                        <button
                            class="mb-0 btn bg-primary ms-auto js-btn-next"
                            @click="imageFileUpload"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                        >
                          Weiter
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <!--single form panel-->
                <div v-if="activeStep === 1" :class="activeStep === 1 ? activeClass : ''"
                     class="p-3 bg-white card">
                  <!--Imge bearbeiten comp-->
                  <div class="mt-4 button-row d-flex flex-column" v-for="(item, idx) in combinedList">
                    <div v-if="item['base64Image'].includes('image') && cropperStep === idx"
                         class="d-flex justify-content-center">
                      <div class="col-12 col-md-8">
                        <h5 class="font-weight-bolder mb-4">Schneide deine Bilder zu</h5>
                        <div :id="'cropper' + idx" :key="idx" class="mb-5">
                          <div style="display: flex; justify-content: center;" class="q-mb-md">
                            <button style="border: 0px;" :style="item.ratio === '1/1' ? 'rgb(126, 17, 69)' : 'grey'" @click="changeRatio(item)" :disabled="item.ratio === '9/16'">9/16</button>
                            <button
                                :style="'background-color:' + item.ratio === '1/1' ? 'rgb(126, 17, 69)' : 'grey'"
                                style="border: 0px;"
                                @click="changeRatio(item)" :disabled="item.ratio === '1/1'">1/1</button>
                          </div>
                          <cropper ref="cropper"
                                   class="cropper"
                                   :stencil-props="{aspectRatio: item.ratio === '9/16' ? 9/16 : 1/1, initialAspectRatio: 9/16}"
                                   :src="item.base64Image"
                                   @change="change"></cropper>
                        </div>
                      </div>
                    </div>
                    <div v-if="item['base64Image'].includes('video') && cropperStep === idx"
                         :class="activeStep === 1 ? activeClass : ''">
                      <video :src="item.base64Image" muted autoplay controls class="col-12 mb-3"></video>
                    </div>
                  </div>
                  <div class="d-flex button-row">
                    <button
                        class="mb-0 btn bg-dark js-btn-next text-white"
                        type="button"
                        title="Zurücksetzen"
                        @click="reset"
                    >
                      Zurücksetzen
                    </button>
                    <button
                        v-if="cropperStep === combinedList.length-1"
                        class="mb-0 btn bg-primary ms-auto js-btn-next"
                        type="button"
                        title="Fortsetzen"
                        @click="lastCropStep(combinedList[cropperStep])"
                    >
                      Weiter
                    </button>
                    <button
                        v-if="cropperStep < combinedList.length-1"
                        class="mb-0 btn bg-primary ms-auto js-btn-next"
                        type="button"
                        title="Fortsetzen"
                        @click="nextCropperStep(combinedList[cropperStep])"
                    >
                      Nächstes
                    </button>
                  </div>
                </div>
                <div v-if="activeStep === 2" :class="activeStep === 2 ? activeClass : ''" class="p-3 card">
                  <h5 class="font-weight-bolder">Hochladen</h5>
                  <label for="textFormInput" class="form-label">Beschreibe um was es sich in deinem Beitrag
                    handelt </label>
                  <div class="row pb-3">
                    <div class="col-auto">
                      <div>
                        <div class="card-body d-flex flex-column justify-content-sm-evenly p-0 mt-3" style="max-height: 43rem">
                          <img v-if="this.checkForImage(resultList[uploadSlider])"
                               class="border-radius-lg"
                               style="max-height: 30rem; object-fit: contain; max-width: 20rem;"
                               :src="resultList[uploadSlider]"
                               alt="product_image"
                          />
                          <div v-if="!this.checkForImage(resultList[uploadSlider])">
                            <video style="max-height: 30rem; object-fit: contain; max-width: 20rem" :src="resultList[uploadSlider]['base64Image']"
                                   muted autoplay controls class="col-12 mb-3"></video>
                          </div>
                          <div style="display: flex; justify-content: space-between;">
                            <soft-button @click="uploadSlider--"
                                         style="margin-top: 10px;" :style="uploadSlider > 0 ? '' : 'display: contents'"
                            >
                              <i class="fa fa-arrow-left"></i>
                            </soft-button>
                            <soft-button v-if="uploadSlider < resultList.length-1" @click="uploadSlider++"
                                         style="margin-top: 10px;">
                              <i class="fa fa-arrow-right"></i>
                            </soft-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col p-3 pb-4">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="mb-3">
                              <textarea class="form-control" id="textFormInput" rows="5" maxlength="1000"
                                        v-model="postText"></textarea>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <div class="mb-3">
                            <label for="hashtagFormInput" class="form-label">Hashtags</label>
                            <textarea class="form-control" id="hashtagFormInput" rows="3" maxlength="1000"
                                      v-model="postHashtags"></textarea>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <div class="mb-3">
                            <label for="hashtagFormInput" class="form-label">Zusatzinformation</label>
                            <textarea class="form-control" id="hashtagFormInput" rows="3" maxlength="1000"
                                      v-model="additionalInfoText"></textarea>
                          </div>
                        </div>
                        <div class="col-12 mb-3">
                          <label class="form-label">Kategorie</label>
                          <select
                              id="category-Select"
                              class="form-control m-auto"
                              name="categorySelect"
                              v-model="category">
                            >
                          </select>
                        </div>
                        <div class="col-6 mb-3">
                          <label>Zeitpunkt</label>
                          <soft-input
                              id="zeitpunkt"
                              v-model="time"
                              dataformatas="HH:mm"
                              filled
                              type="time"
                              hint="Zeitpunkt der Veröffentlichung"
                          />
                        </div>
                        <div class="col-6 mb-3">
                          <label>Datum</label>
                          <soft-input
                              id="datum"
                              v-model="date"
                              filled
                              type="date"
                              hint="Datum der Veröffentlichung"
                          />
                        </div>
                        <div class="d-flex align-items-center flex-column">
                          <div class="form-label mb-2">Social-Media-Plattform (min. 1)</div>
                          <div class="gap-3 d-flex flex-wrap" id="social-platform">
                            <button type="button" style="border:1px" :style="instagramStyle" class="badge badge-lg"
                                    @click="this.instagram = !this.instagram">
                              <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
                            </button>
                            <button type="button" style="border:1px" :style="facebookStyle" class="badge badge-lg"
                                    @click="this.facebook = !this.facebook">
                              <font-awesome-icon :icon="['fab', 'facebook']" size="lg" />
                            </button>
                            <button type="button" style="border:1px" :style="linkedinStyle" class="badge badge-lg"
                                    @click="this.linkedin = !this.linkedin">
                              <font-awesome-icon :icon="['fab', 'linkedin']" size="lg" />
                            </button>
                          </div>
                          <div v-if="socialMediaErrorMessage" class="text-danger d-flex align-items-center mt-2">
                            Bitte wählen Sie mindestens
                            Social-Media-Platform
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex button-row">
                      <button
                          class="mb-0 btn bg-dark js-btn-next text-white"
                          type="button"
                          title="Zurücksetzen"
                          @click="reset"
                      >
                        Zurücksetzen
                      </button>
                      <button
                          class="mb-0 btn bg-primary ms-auto js-btn-next"
                          type="button"
                          title="Hochladen"
                          :disabled="!PostUploadedList.length > 0"
                          @click="upload"
                      >
                        Hochladen
                      </button>
                    </div>
                  </div>
                </div>
                <!--single form panel-->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";

import SinglePostView from "./SinglePostView.vue";
import api, {apiGet, apiPost} from "@/api/api";
import {Cropper} from "vue-advanced-cropper";
import VueCropper from "@/components/VueCropper.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftInput from "@/components/SoftInput.vue";
import * as Choices from "choices.js";
import {ref} from "vue";

export default {
  name: "Uploader",
  components: {SoftInput, SoftButton, VueCropper, Cropper, SinglePostView },

  data() {
    return {
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
      message: "",
      date: new Date().toISOString().slice(0, 10),
      time: "18:00",
      fileUploader: null,
      PostUploadedList: [],
      imageCropListData: [],
      postText: '',
      category: '',
      postHashtags: '',
      choicesInstants: null,
      postUsage: 'Beitrag',
      additionalInfoText: '',
      instagram: false,
      facebook: false,
      linkedin: false,
      restrictScrollEvent: 1,
      socialMediaErrorMessage: false,
      imgTooSmall: false,
      noCategories: false,
      firstCategory: '',
      base64Video: [],
      cropperStep: 0,
      combinedList: [],
      coordinates: ref(''),
      canvas: ref(''),
      cropImg: "",
      resultList: [],
      uploadSlider: 0,
    };
  },
  async mounted() {
    this.$store.state.showNavbarMini = false;
    this.$store.state.isAbsolute = true;
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    this.createDropzone()
    if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
      this.$store.state.activeId = localStorage.getItem('companyId')
    }
  },
  watch: {
    imageCropList(val) {
      for (const [idx, item] of val.entries()) {
        this.imageCropListData.push({
          ...item,
        })
      }
      this.combinedList = []
      for (let i = 0; i < this.imageCropListData.length; i++) {
        this.combinedList.push({base64Image: this.imageCropListData[i]['base64Image']});
      }
    }
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
    const categories = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
    this.categories = categories.data.map((x) => {
      return x.category
    })
    if (this.categories.length === 0) {
      this.noCategories = true
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    imageCropList() {
      return this.PostUploadedList ? this.PostUploadedList.map((x) => {
        return {
          ...x
        }
      }) : []
    },
    instagramStyle() {
      return this.instagram ? 'color: white; background-color: #e91e63' : 'color: grey'
    },
    facebookStyle() {
      return this.facebook ? 'color: white; background-color: #1a237e' : 'color: grey'
    },
    linkedinStyle() {
      return this.linkedin ? 'color: white; background-color: #2196f3' : 'color: grey'
    },
    validateSocialMedia() {
      return this.instagram || this.facebook || this.linkedin
    },
    uploadData() {
      let media = this.resultList.map((x) => {
        let val = x
        if (typeof x === 'object')
        {
          val = x['base64Image']
        }
        return {
          base64Image: val
        }
      })
      return {
        images: media,
        text: encodeURI(this.postText.trim()),
        hashtags: encodeURI(this.postHashtags.trim()),
        additionalInfo: encodeURI(this.additionalInfoText.trim()),
        usage: this.postUsage,
        category: this.category,
        instagramText: this.instagram ? 'true' : '',
        facebookText: this.facebook ? 'true' : '',
        linkedinText: this.linkedin ? 'true' : '',
        publicationDate: new Date(this.date + ' ' + this.time),
      }
    }
  },
  methods: {
    createDropzone: function () {
      Dropzone.autoDiscover = false;
      const drop = document.getElementById("dropzone");
      this.fileUploader = null;
      const self = this;
      this.base64Video = []; // Initialize as an array to store base64 strings.

      this.fileUploader = new Dropzone(drop, {
        url: '/',
        maxFilesize: 5, // 10MB
        acceptedFiles: ".jpg, .png, .gif, .mp4",
        autoProcessQueue: false,
        reload: false,
        addRemoveLinks: true,
        dictRemoveFile: 'Entfernen',
        dictDefaultMessage: 'Datei hier hochladen',
        init: function () {
          this.on("addedfile", function (file) {
            var reader = new FileReader();
            reader.onload = function (event) {
              // Handle base64 encoding for non-video files if needed
              var base64String = event.target.result;
              var fileName = file.name;
            };
            if (file.type === 'video/mp4') {
              let videoElement = document.createElement('video');
              videoElement.src = URL.createObjectURL(file);

              // Convert video to base64 and push to array
              const videoPromise = new Promise((resolve, reject) => {
                videoElement.addEventListener('loadedmetadata', async () => {
                  try {
                    const response = await fetch(videoElement.src);
                    const blob = await response.blob();

                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  } catch (error) {
                    reject(error);
                  }
                });
                // Load the video metadata if not already done
                videoElement.load();
              });
              videoPromise.then(base64String => {
                self.base64Video.push({
                  base64String: base64String,
                  name: file.name,
                }); // Push base64 string to the array
              }).catch(error => {
                console.error("Error processing video:", error);
              });
            }
            reader.readAsDataURL(file);
          });
        }
      });
    },

    nextCropperStep(item) {
      if (this.checkForImage(item)) {
        this.cropImage()
      }
      this.cropperStep++;
    },
    checkForImage(item){
      let val = item
      if (typeof item === 'object')
      {
        val = item['base64Image']
      }
      return val.includes('image')
    },
    clear() {
      this.firstCategory = ''
    },
    async newCategory() {
      if (this.firstCategory !== '') {
        this.noCategories = false
        let resp
        let companyId
        if (JSON.parse(localStorage.getItem('companyId'))) {
          companyId = JSON.parse(localStorage.getItem('companyId'));
        }
        if (JSON.parse(sessionStorage.getItem('companyId'))) {
          companyId = JSON.parse(sessionStorage.getItem('companyId'));
        }
        resp = await apiPost(companyId, '/company/newCategory?companyId=' + this.$store.state.activeId, {
          category: this.firstCategory
        }).then(async () => {
          const categories = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
          this.categories = categories.data.map((x) => {
            return x.category
          })
        })
      }
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      this.resultList.push(result.canvas.toDataURL("image/jpg"))
    },
    change({coordinates, canvas}) {
      this.coordinates = coordinates;
      this.canvas = canvas;
    },

    imageFileUpload() {
      //TODO CHECK FILE SIZE FOR OVER 10MB
      this.PostUploadedList = []

      this.PostUploadedList = this.fileUploader.files.map((x) => {
          this.imgTooSmall = (x.height < 1080 || x.width < 1080)

          return {
            base64Image: x.dataURL,
            ratio: "9/16"
          }
        }
      )

      this.PostUploadedList = this.PostUploadedList.filter((item) => item.base64Image !== undefined);
      this.combinedList = this.PostUploadedList

      let videoList = []
      for (let item of this.base64Video)
      {
        for (let file of this.fileUploader.files)
        {
          if (file.name === item.name)
          {
            videoList.push(item)
          }
        }
      }

      for (var i = 0; i < videoList.length; i++)
      {
        this.resultList.push({base64Image: videoList[i].base64String})
        this.combinedList.push({base64Image: videoList[i].base64String})
      }

      if (this.combinedList.length <= 5 && this.combinedList.length !== 0) {
        this.message = ""
        if (!this.noCategories) {
          this.nextStep()
        }
      } else if (this.combinedList.length === 0) {
        this.message = "Du musst mindestens eine Datei hochladen!"
        setTimeout(() => {
          this.message = ""
        }, 3000);
      } else {
        this.message = "Du kannst nicht mehr als 5 Bilder pro Post hochladen!"
        setTimeout(() => {
          this.message = ""
        }, 3000);
      }
    },
    async nextStep() {
      if (!this.imgTooSmall) {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;

        } else {
          this.activeStep -= 1;
        }
      } else {
        this.PostUploadedList = []
        this.message = "Das Bild hat zu wenig Pixel"
        setTimeout(() => {
          this.message = ""
        }, 3000);
      }
    },
    lastCropStep(item){
      if (this.checkForImage(item)){
        this.cropImage()
      }
      this.nextStep()
    },
    log(){
      console.log("Die Konsole sagt:")
      console.log(this.combinedList)
    },
    async handleScroll() {
      if (this.$store.state.activeId === undefined || this.$store.state.activeId === null) {
        this.$store.state.activeId = localStorage.getItem('companyId')
      }
      if (this.activeStep === this.formSteps && this.restrictScrollEvent === 1) {
        if (document.getElementById("category-Select") && this.choicesInstants === null) {
          const status = document.getElementById("category-Select");
          this.choicesInstants = new Choices(status, {
            itemSelectText: '',
            searchEnabled: false,
          })

          const resp = await apiGet(localStorage.getItem('adminId'), '/company/categories?companyId=' + this.$store.state.activeId)
          const categoryOptions = []
          let category = null

          for (let i = 0; i < resp.data.length; i++) {
            category = {
              id: resp.data[i].id,
              name: resp.data[i].category,
              selected: i === 0
            }
            categoryOptions.push(category);
          }


          for (category in categoryOptions) {
            if (categoryOptions[category].selected === true) {
              this.category = categoryOptions[category].name
            }
          }

          this.choicesInstants.setChoices(categoryOptions,
              'name', 'name', false)
        }
        this.restrictScrollEvent = 2
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    reset() {
      this.$router.go(0)
    },
    async upload() {
      this.socialMediaErrorMessage = false
      if (this.validateSocialMedia) {
        if (this.category !== '') {
          localStorage.setItem('companyId', this.$store.state.activeId)
          await api.post('/post/save?companyId=' + this.$store.state.activeId.toString(), this.uploadData).then(() => {
            this.$router.push({name: 'Posts'})
          })
        } else {
          this.noCategory = true;
        }
      } else {
        this.socialMediaErrorMessage = true;
      }
    },
    changeRatio(item) {
      if (item.ratio === "1/1")
      {
        item.ratio = "9/16"
      }
      else {
        item.ratio = "1/1"
      }
      this.$refs.cropper.reset();
    }
  },
}
</script>
<style>
#exampleModal {
  top: 200px;
}

.modal-dialog {
  padding-left: 100px;
}

.twitter-cropper {
  height: 300px;

  &__background {
    background-color: #edf2f4;
  }

  &__foreground {
    background-color: #edf2f4;
  }

  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
</style>

