<template>
  <div class="py-4 container-fluid ">

    <button class="mb-0 btn bg-primary ms-auto justify-content-end d-flex" v-if="showSend" @click="sendNotification">
        <i class="fa fa-envelope m-auto" aria-hidden="true"></i>
      <span class="ms-2">Kunden benachrichtigen</span>
    </button>
    <span class="d-flex justify-content-end info">
      {{ sendMessage }}
    </span>
    <div class="mt-3 kanban-container">
      <div class="py-2 min-vh-100 d-inline-flex" style="overflow-x: auto">
        <div id="myKanban"></div>
      </div>
    </div>
    <div
        id="jkanban-info-modal-backdrop"
        class="fixed inset-0 z-40 hidden bg-black opacity-50"
    ></div>
  </div>
</template>

<script>
import "jkanban/dist/jkanban.min.js";
import "jkanban/dist/jkanban.js";
import "jkanban/dist/jkanban.min.css";
import api, {apiGet} from "@/api/api";
import moment from "moment";

export default {
  name: "Kanban",
  data() {
    return {
      stateColor: "red",
      showMenu: false,
      loadingData: false,
      company: {
        name: ''
      },
      postList: [],
      emptyString: "",
      kanbanInstance: "",
      showSend: false,
      showSendMessage: false,
      sendMessage: "",
    };
  },
  computed: {
    activeId() {
      return parseInt(this.$store.state.activeId);
    },

  },

  methods: {
    async destroyKanban() {
      if (this.kanbanInstance) {
        this.kanbanInstance = null;
        const container = document.getElementById('myKanban');
        container.innerHTML = '';
      }
    },

    getFirstFrameAsBase64(base64Video) {
      return new Promise((resolve, reject) => {
        // Create video and canvas elements
        const videoElement = document.createElement('video');
        videoElement.muted = true
        const canvasElement = document.createElement('canvas');
        const context = canvasElement.getContext('2d');

        // Set video source to the base64 video string
        videoElement.src = base64Video;
        videoElement.crossOrigin = 'anonymous'; // Avoid CORS issues if needed

        // Wait for video metadata to load
        videoElement.addEventListener('loadedmetadata', () => {
          // Set canvas size to match the video's dimensions
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;

          // Capture the first frame when the video is ready
          videoElement.currentTime = 0;
        });

        // Capture the first frame as soon as it is loaded
        videoElement.addEventListener('seeked', () => {
          // Draw the first frame onto the canvas
          context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

          // Convert the canvas content to a base64 image string
          const base64Image = canvasElement.toDataURL('image/png');
          resolve(base64Image);
        });

        // Handle video loading errors
        videoElement.onerror = () => {
          reject('Failed to load video.');
        };
      });
    },
    successMessage() {
      this.showSendMessage = true
      setTimeout(() => {
        this.sendMessage = ""
      }, 3000)
    },
    async sendNotification(){
      api.post('mail/review-on-press?companyId=' + this.activeId);
      this.sendMessage = "Benachrichtigung wurde versand"
      this.successMessage()
    },

    async setKanban() {
      try {
        let resp;
        this.loadingData = true;
        resp = await apiGet(localStorage.getItem('adminId'),'/post/postWithImages?companyId=' + this.activeId)

        const processPosts = async () => {
          const processedPosts = await Promise.all(


              resp.data.posts.map(async (x, idx) => {
                console.log(x)
                let tempImage;
                console.log("123: " + x.images.length !== 0);
                // Check if the image is a video
                if(x.images.length !== 0){
                  if (x.images[0].base64Image.includes('video')) {
                    try {
                      // Wait for the first frame extraction
                      tempImage = await this.getFirstFrameAsBase64(x.images[0].base64Image);
                    } catch (error) {
                      console.error(`Error extracting frame from video at index ${idx}:`, error);
                      tempImage = x.images[0].base64Image; // Fallback in case of error
                    }
                  } else {
                    tempImage = x.images[0].base64Image;
                  }


                }
                // Construct the post object
                return {
                  ...x,
                  images: [{ 'base64Image': tempImage }],
                  text: decodeURI(x.text),
                  hashtags: decodeURI(x.hashtags),
                  status_id: decodeURI(x.status_id),
                  publicationDate: decodeURI(moment(x.publicationDate).format('DD.MM.yyyy')),
                  instaBadge: x.instagramText !== '' ? '<i class="fa brands fa-instagram col-1 fa-sm"></i>' : '',
                  facebookBadge: x.facebookText !== '' ? '<i class="fa brands fa-facebook col-1 fa-sm"></i>' : '',
                  linkedinBadge: x.linkedinText !== '' ? '<i class="fa brands fa-linkedin col-1 fa-md"></i>' : ''
                };
              })
          );

          // Set the processed list to postList after all promises resolve
          this.postList = processedPosts;
        };

        await processPosts().then(() => {
        });
        this.postList = this.postList.reverse()
        this.showSend= this.postList.filter((x) => x.status === 'IN_EXAMINATION').length > 0
        this.company = resp.data.company
        this.loadingData = false;
        this.kanbanInstance = document.getElementById("myKanban")

        if (this.kanbanInstance) {
          await this.destroyKanban();

          this.kanbanInstance = new jKanban({
            element: "#myKanban",
            gutter: "10px",
            dragBoard: false,
            widthBoard: "250px",
            dropEl: (el, target, source, sibling) => {

              let item = el.closest("div.kanban-item");
              let itemID = item.getAttribute("data-eid");
              let boardId = target.closest("div.kanban-board").getAttribute("data-id");
              let examinationBoard = (document.querySelectorAll('[data-id = "_examination"]'))

              this.showSend = examinationBoard[0].children[1].children.length > 0

              if (boardId === '_open') {
                this.itemStatus = "Offen";
              } else if (boardId === '_progress') {
                this.itemStatus = "In Bearbeitung";
              } else if (boardId === '_examination') {
                this.itemStatus = "Zur Kundenfreigabe";
              } else if (boardId === '_approved') {
                this.itemStatus = "Freigegeben";
              } else {
                this.itemStatus = "Fehler";
              }

              api.put('post/status', {
                postId: itemID,
                status: this.itemStatus,
              });
            },
            boards: [
              {
                id: "_open",
                title: "Offen",
                item: this.postList.filter((x) => x.status === 'OPEN').map((item, idx) => {
                  return {
                    title:
                        '<div class="card-body d-flex p-0" style="height: 150px">' +
                        '<div class="row d-flex flex-wrap flex-row align-content-stretch align-items-start">' +
                        '<div class="col-7 h-100">' +
                        '<div class="d-flex flex-column justify-content-start h-100">' +
                        '<p class="mb-1 text-xs text-' + this.stateConverterColor(item) + '">' + this.stateConverterName(item) + '</p>' +
                        '<h5 class="font-weight-bold text-ellipsis text-sm">' + item.text + '</h5>' +
                        '<p class="mb-1 text-ellipsis text-xs">' + item.hashtags + '</p>' +
                        '<p class="text-xs">' + item.publicationDate + '</p>' +
                        '<div class="row text-xs">' +
                        item.instaBadge +
                        item.facebookBadge +
                        item.linkedinBadge +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '<div class="col-5 d-flex align-items-center text-center order-lg-last justify-content-center h-100">' +
                        '<img src="' + item.images[0].base64Image + '" class="border-radius-lg z-index-2" style=" object-fit: cover; width: 90%" alt="postImg">' +
                        '</div>' +
                        '</div>' +
                        '</div>',
                    class: ["border-radius-md"],
                    id: [item.id],
                    click: () => {
                      this.$router.push('/single-post?postId=' + item.id + '&c=' + this.activeId)
                    },
                  }
                })
              },
              {
                id: "_progress",
                title: "In Bearbeitung",
                item: this.postList.filter((x) => x.status === 'IN_POGRESS').map((item) => {
                  return {
                    title:
                        '<div class="card-body d-flex p-0" style="height: 150px">' +
                        '<div class="row d-flex flex-wrap flex-row align-content-stretch align-items-start">' +
                        '<div class="col-7 h-100">' +
                        '<div class="d-flex flex-column justify-content-start h-100">' +
                        '<p class="mb-1 text-xs text-' + this.stateConverterColor(item) + '">' + this.stateConverterName(item) + '</p>' +
                        '<h5 class="font-weight-bold text-ellipsis text-sm">' + item.text + '</h5>' +
                        '<p class="mb-1 text-ellipsis text-xs">' + item.hashtags + '</p>' +
                        '<p class="text-xs">' + item.publicationDate + '</p>' +
                        '<div class="row text-xs">' +
                        item.instaBadge +
                        item.facebookBadge +
                        item.linkedinBadge +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '<div class="col-5 d-flex align-items-center text-center order-lg-last justify-content-center h-100">' +
                        '<img src="' + item.images[0].base64Image + '" class="border-radius-lg z-index-2" style=" object-fit: cover; width: 100%" alt="postImg">' +
                        '</div>' +
                        '</div>' +
                        '</div>',
                    class: ["border-radius-md"],
                    id: [item.id],
                    click: () => {
                      this.$router.push('/single-post?postId=' + item.id + '&c=' + this.activeId)
                    },
                  }
                })
              },
              {
                id: "_examination",
                title: "Zur Kundenfreigabe",
                item: this.postList.filter((x) => x.status === 'IN_EXAMINATION').map((item, idx) => {
                  return {
                    title:
                        '<div class="card-body d-flex p-0" style="height: 150px">' +
                        '<div class="row d-flex flex-wrap flex-row align-content-stretch align-items-start">' +
                        '<div class="col-7 h-100">' +
                        '<div class="d-flex flex-column justify-content-start h-100">' +
                        '<p class="mb-1 text-xs text-' + this.stateConverterColor(item) + '">' + this.stateConverterName(item) + '</p>' +
                        '<h5 class="font-weight-bold text-ellipsis text-sm">' + item.text + '</h5>' +
                        '<p class="mb-1 text-ellipsis text-xs">' + item.hashtags + '</p>' +
                        '<p class="text-xs">' + item.publicationDate + '</p>' +
                        '<div class="row text-xs">' +
                        item.instaBadge +
                        item.facebookBadge +
                        item.linkedinBadge +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '<div class="col-5 d-flex align-items-center text-center order-lg-last justify-content-center h-100">' +
                        '<img src="' + item.images[0].base64Image + '" class="border-radius-lg z-index-2" style=" object-fit: cover; width: 100%" alt="postImg">' +
                        '</div>' +
                        '</div>' +
                        '</div>',
                    class: ["border-radius-md"],
                    id: [item.id],
                    click: () => {
                      this.$router.push('/single-post?postId=' + item.id + '&c=' + this.activeId)
                    },
                  }
                })
              },
              {
                id: "_approved",
                title: "Freigegeben",
                item: this.postList.filter((x) => x.status === 'APPROVED').map((item, idx) => {
                  return {
                    title:
                        '<div class="card-body d-flex p-0" style="height: 150px">' +
                        '<div class="row d-flex flex-wrap flex-row align-content-stretch align-items-start">' +
                        '<div class="col-7 h-100">' +
                        '<div class="d-flex flex-column justify-content-start h-100">' +
                        '<p class="mb-1 text-xs text-' + this.stateConverterColor(item) + '">' + this.stateConverterName(item) + '</p>' +
                        '<h5 class="font-weight-bold text-ellipsis text-sm">' + item.text + '</h5>' +
                        '<p class="mb-1 text-ellipsis text-xs">' + item.hashtags + '</p>' +
                        '<p class="text-xs">' + item.publicationDate + '</p>' +
                        '<div class="row text-xs">' +
                        item.instaBadge +
                        item.facebookBadge +
                        item.linkedinBadge +
                        '</div>' +
                        '</div>' +
                        '</div>' +
                        '<div class="col-5 d-flex align-items-center text-center order-lg-last justify-content-center h-100">' +
                        '<img src="' + item.images[0].base64Image + '" class="border-radius-lg z-index-2" style=" object-fit: cover; width: 100%" alt="postImg">' +
                        '</div>' +
                        '</div>' +
                        '</div>',
                    class: ["border-radius-md"],
                    id: [item.id],
                    click: () => {
                      this.$router.push('/single-post?postId=' + item.id + '&c=' + this.activeId)
                    },
                  }
                })
              },
            ]
          });

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    stateConverterName(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'Vollständig' : nbr === 1 ? 'Unvollständig' : nbr === 2 ? 'Fertig' : 'Fehler'
    },

    stateConverterColor(item) {
      const nbr = item.text !== '' ? 0 : item.text !== '' ? 1 : item.text === '' ? 1 : 2
      return nbr === 0 ? 'success' : nbr === 1 ? 'warning' : 'dark'
    },
  },
  async mounted() {
    try {
      this.$store.state.showNavbarMini = false;
      this.$store.state.isAbsolute = true;
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      sidenav_show.children[0].style.setProperty("background-color", "white", "important");

      await this.setKanban();
    } catch (error) {
      console.error("Error in mounted:", error);
    }
  },
}
;
</script>
<style>
.kanban-container {
  display: flex;
  border: none;
}

#myKanban {
  flex: 1;
  margin-right: 10px;

}
</style>
